import http from "@/services/api";
import { mountUrlApiTransparencia } from "@/utils/mountURL";

export function sendGetAllLicitacoes(query = ""): Promise<any> {
  let url = mountUrlApiTransparencia(
    "/licitacao?_orderBy=dataabertura&_order=desc"
  );
  url = query != "" ? url + "&" + query : url;
  return http
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export async function sendGetLicitacaoById(id: any) {
  const url = mountUrlApiTransparencia(`/licitacao/${id}`);
  return await http.get(url);
  // return await http.get(url)
  //   .then( (response) => {
  //     return response;
  //   })
  //   .catch( (error) => {
  //     console.log(error);
  //     throw error;
  //   });
}

export function sendGetDocAnexosById(id: any): Promise<any> {
  const url = mountUrlApiTransparencia(
    `/licitacao/${id}/anexos?_orderBy=data&_order=desc`
  );
  return http
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function getSituacoesValues() {
  return [
    { value: 1, title: "Aberta" },
    { value: 2, title: "Anulada" },
    { value: 3, title: "Revogada" },
    { value: 4, title: "Encerrada" },
    { value: 5, title: "Suspensa" },
  ];
}

export function getSituacaoTitle(situacao: any) {
  const res: any = getSituacoesValues().find((item: any) => {
    return item.value == situacao;
  });
  if (res == undefined) {
    return situacao;
  }
  return res.title;
}

export function getModalidadesValues() {
  return [
    { value: 1, title: "Convite" },
    { value: 2, title: "Dispensa de Licitação" },
    { value: 3, title: "Tomada de Preços" },
    { value: 4, title: "Pregão Presencial" },
    { value: 5, title: "Leilão" },
    { value: 6, title: "Concorrência" },
    { value: 7, title: "Pregão eletrônico" },
    { value: 8, title: "Chamamento Pública" },
    { value: 9, title: "Chamada Pública" },
    { value: 10, title: "Convite" },
    { value: 11, title: "Inexigibilidade" },
    { value: 12, label: "Credenciamento" },
  ];
}
export function getModalidadeTitle(modalidade: any) {
  const res: any = getModalidadesValues().find((item: any) => {
    return item.value == modalidade;
  });
  if (res == undefined) {
    return modalidade;
  }
  return res.title;
}

export default {
  sendGetAllLicitacoes,
  getSituacaoTitle,
  getModalidadeTitle,
  sendGetLicitacaoById,
  sendGetDocAnexosById,
};
