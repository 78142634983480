import { Component, Vue } from "vue-property-decorator";
import licitacoes from "@/modules/licitacoes/services/licitacoes";
import MountFiltro from "@/components/mountFiltro/mountFiltro.vue";
import utils from "@/utils";

import { Icons } from "@/components/base";

@Component({
  components: {
    Icons,
    MountFiltro,
  },
})
export default class LicitacoesList extends Vue {
  isLoading = false;
  showFiltro = true;
  list = [];
  pageNow = 1;
  pagination = {
    count: 0,
    countTotal: 0,
    pages: 0,
    per_page: 10,
  };
  query: any = {
    _page: "1",
    _limit: "10",
  };

  ajustDate = utils.date.strDatetimeUSAToBR;
  ajustHour = utils.date.ajustHour;

  getSituacao = licitacoes.getSituacaoTitle;
  getModalidade = licitacoes.getModalidadeTitle;

  mounted() {
    this.getAllItens(this.query);
  }

  clickItem(item: any) {
    this.$router.push(`/transparencia/licitacoes/${item.idLicitacao}`);
  }

  getAllItens(queryObj: any) {
    this.isLoading = true;
    const query = utils.queryURL.jsonToURLQuery(queryObj);
    licitacoes
      .sendGetAllLicitacoes(query)
      .then((response) => {
        this.list = response.data.list;
        this.pagination.count = response.data.count;
        this.pagination.countTotal = response.data.totalitems;
        this.pagination.pages = response.data.total;
        this.query = queryObj;
      })
      .catch(() => {
        this.list = [];
      })
      .finally(() => (this.isLoading = false));
  }

  changePagination(page: any) {
    this.query._page = page;
    window.scrollTo(0, 0);
    this.getAllItens(this.query);
  }

  onMountQueryFiltroLicitacao(filterStr: any, filterObj: any) {
    if (Object.keys(filterObj).length == 0) {
      this.query = {
        _page: "1",
        _limit: "10",
      };
      this.pageNow = 1;
    }
    if (filterObj.dataabertura != undefined) {
      filterObj.dataabertura = `%25${filterObj.dataabertura}%25`;
    }
    if (filterObj.objeto != undefined) {
      filterObj.objeto = `%25${filterObj.objeto}%25`;
    } else if (this.query.page != "1") {
      this.query._page = "1";
      this.pageNow = 1;
    }
    const query = { ...this.query, ...filterObj };
    this.getAllItens(query);
  }

  formFiltroLicitacao = [
    [
      {
        size: 2,
        name: "dataabertura",
        label: "Ano",
        type: "number",
        placeholder: "Ano",
        isLike: true,
      },
      {
        size: 3,
        name: "fkmodalidade",
        label: "Modalidade",
        type: "select",
        options: [
          { value: 1, label: "Convite" },
          { value: 2, label: "Dispensa de Licitação" },
          { value: 3, label: "Tomada de Preços" },
          { value: 4, label: "Pregão Presencial" },
          { value: 5, label: "Leilão" },
          { value: 6, label: "Concorrência" },
          { value: 7, label: "Pregão eletrônico" },
          { value: 8, label: "Chamamento Pública" },
          { value: 9, label: "Chamada Pública" },
          { value: 10, label: "Convite" },
          { value: 11, label: "Inexigibilidade" },
          { value: 12, label: "Credenciamento" },
        ],
      },
      {
        size: 3,
        name: "fksituacao",
        label: "Situação",
        type: "select",
        options: [
          { value: 1, label: "Aberta" },
          { value: 2, label: "Anulada" },
          { value: 3, label: "Revogada" },
          { value: 4, label: "Encerrada" },
          { value: 5, label: "Suspensa" },
        ],
      },
      {
        size: 4,
        name: "objeto",
        label: "Objeto",
        type: "text",
        placeholder: "Objeto",
        isLike: true,
      },
    ],
  ];
}
